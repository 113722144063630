/* Fade */
@media (prefers-reduced-motion: no-preference) {
  .fade-enter {
    opacity: 0;
    transition: opacity 1000ms;
  }

  .fade-enter-active {
    opacity: 1;
  }

  .fade-appear {
    opacity: 0;
  }

  .fade-appear-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active,
  .fade-exit-done {
    opacity: 0;
    transition: opacity 300ms;
  }

  /* End of fade */

  /* Show up */
  .show-up-enter,
  .show-up-appear {
    transform: translateY(100%);
    opacity: 0;
  }

  .show-up-enter-active,
  .show-up-appear-active {
    transform: translateY(0px);
    opacity: 1;
    transition-duration: 300ms;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }

  .show-up-exit {
    transform: translateY(0px);
    opacity: 1;
    margin-top: 0;
  }

  .show-up-exit-active,
  .show-up-exit-done {
    transform: translateY(100%);
    transition-duration: 300ms;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    opacity: 0;
  }

  /* Mobile Show up */

  @media (max-width: 767px) {
    .xs-show-up-enter,
    .xs-show-up-appear {
      transform: translateY(100%);
      opacity: 0;
    }

    .xs-show-up-enter-active,
    .xs-show-up-appear-active {
      transform: translateY(0px);
      opacity: 1;
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    }

    .xs-show-up-exit {
      transform: translateY(0px);
      opacity: 1;
      margin-top: 0;
    }

    .xs-show-up-exit-active,
    .xs-show-up-exit-done {
      transform: translateY(100%);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
      opacity: 0;
    }
  }

  /* End of mobile show up */

  /* Show left */
  .show-left-enter,
  .show-left-appear {
    transform: translateX(-200px);
    opacity: 0;
    margin-top: -80px;
  }

  .show-left-enter-active,
  .show-left-appear-active {
    transform: translateX(0px);
    opacity: 1;
    margin-top: 0;
    transition-duration: 400ms;
    transition-property: opacity, transform, margin-top;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }

  .show-left-exit {
    transform: translateX(0px);
    opacity: 1;
    margin-top: 0;
  }

  .show-left-exit-active,
  .show-left-exit-done {
    transform: translateX(-200px);
    margin-top: -80px;
    opacity: 0;
    transition-duration: 400ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  /* End of show left */

  /* Show right-full full */
  .show-right-full-enter,
  .show-right-full-appear {
    transform: translateX(100%);
    opacity: 0;
  }

  .show-right-full-enter-active,
  .show-right-full-appear-active {
    transform: translateX(0px);
    opacity: 1;
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }

  .show-right-full-exit {
    transform: translateX(0px);
    opacity: 1;
  }

  .show-right-full-exit-active,
  .show-right-full-exit-done {
    transform: translateX(100%);
    opacity: 0;
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  }
  /* End of show left-full full */
}

/* Slide down */
.slide-down-enter,
.slide-down-appear {
  opacity: 1;
  margin-bottom: -25%;
  transform: translateY(-100%);
}

.slide-down-enter-active,
.slide-down-appear-active {
  transform: translateY(0);
  margin-bottom: 0;
  opacity: 1;
  transition: margin-bottom 500ms ease-out;
  transition-property: transform, margin-bottom, opacity;
}

.slide-down-exit {
  height: 100%;
  will-change: height;
}

.slide-down-exit-active,
.slide-down-exit-done {
  height: 0px;
  transition-duration: 500ms;
  transition-property: transform, height;
  transition-timing-function: ease-out;
}
/* End of slide down */

/* Pop */
.pop-enter,
.pop-appear {
  transform: scale(0.8);
  opacity: 0;
}

.pop-enter-active,
.pop-appear-active {
  transform: scale(1);
  opacity: 1;
  transition-duration: 100ms;
  transition-property: opacity, transform, margin-top;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

.pop-exit {
  transform: scale(1);
  opacity: 1;
  margin-top: 0;
}

.pop-exit-active,
.pop-exit-done {
  transform: scale(0.9);
  opacity: 0;
  transition-duration: 100ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.5, 1, 0.25, 1);
}
/* End of pop */
