@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/source-sans-pro-400.woff2) format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/source-sans-pro-600.woff2) format("woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/source-sans-pro-700.woff2) format("woff2");
}

* {
  font-family: "Source Sans Pro", sans-serif;

  @apply text-emphasis-high;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1 {
  @apply mb-8 text-4xl;
  margin-bottom: 24px;
}

h2 {
  @apply mb-6 text-3xl;
  margin-bottom: 20px;
}

h3 {
  @apply mb-4 text-2xl;
  margin-bottom: 16px;
}

h4 {
  @apply mb-3 text-xl;
  margin-bottom: 14px;
}

h5 {
  @apply mb-2 text-lg font-semibold;
  margin-bottom: 12px;
}

h6 {
  @apply mb-2 text-base font-semibold;
  margin-bottom: 12px;
}

p {
  @apply text-base;
}

a {
  text-decoration: underline;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  @apply text-emphasis-high;

  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

main {
  @apply !pb-20 p-5 w-full bg-white box-border;

  min-height: calc(100dvh - 48px);
}

main.new {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 18px;
}

@media (max-width: 767px) {
  main.new {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  main {
    @apply !pb-5;
  }
}

label {
  display: block;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 0.4em;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

input:disabled {
  color: #ccc;
}

input[type="range"] {
  height: 0;
}

button {
  color: #333;
  background-color: #f4f4f4;
  outline: none;
}

button:focus {
  border-color: #666;
}

.btn {
  @apply flex inline-block items-center justify-center font-semibold shadow transition-colors;
  @apply rounded-[10px] px-8 py-3;

  letter-spacing: 0.5px;
  min-width: 140px;
  box-sizing: border-box;
  min-width: 90px;
  font-size: 1rem;
  transition: background-color 0.1s ease-in;
  background: none;

  text-decoration: none;
  text-align: center;

  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  cursor: pointer;

  white-space: nowrap;
}

.btn:not(.btn-secondary) {
  border: none;
}

.btn-primary {
  @apply bg-primary-400 hover:bg-primary-500;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #444;
}

.btn-text {
  @apply transition-colors;

  box-shadow: none;
  background: none;
  font-weight: 400;
}

.btn-text:disabled {
  @apply text-emphasis-low;
}

.btn-text.btn-primary,
.btn-text.btn-primary span {
  @apply text-primary-700 hover:bg-opacity-0;
}

.btn-text.btn-light {
  @apply text-disabled;
}

.btn-secondary {
  @apply pb-2 pt-2 text-primary-700 visited:text-primary-700 hover:text-white hover:bg-primary-700 active:bg-primary-800 border-2 border-solid border-primary-600 hover:border-primary-700 active:border-primary-800 focus:border-primary-800;
}

.btn-secondary span {
  @apply text-primary-700 visited:text-primary-700;
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  @apply text-primary-200 border-primary-200;
}

.btn-tertiary {
  @apply pb-2 pt-2 text-white hover:bg-secondary-700 bg-secondary-800 active:bg-secondary-900 border-2 border-solid hover:border-secondary-700 border-secondary-800 active:border-secondary-900 focus:border-secondary-900;
}

.btn-tertiary span {
  @apply text-white;
}

.btn-tertiary:disabled,
.btn-tertiary.disabled {
  @apply text-secondary-200 bg-secondary-200 border-secondary-200;
}

.btn-share {
  @apply text-white;

  background-color: #1877f2;
}

.btn-share:disabled {
  background-color: lighten(#1877f2, 10%);
}

input[type="submit"] {
  outline: none;
}

/* Text */

.caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.body1 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.body2 {
  @apply text-emphasis-medium;

  font-size: 0.9rem;
  line-height: 1.26rem;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.overline {
  @apply text-sm uppercase;

  letter-spacing: 1.5px;
}

.button {
  letter-spacing: 1.25px;
  font-size: 14px;
}

p {
  font-size: 16px;
  margin: 12px 0;
}

a {
  font-size: 16px;
  color: #333;
}

a.disabled {
  border-color: #ccc;
}

.disabled {
  @apply opacity-70;
}

[data-disabled] {
  @apply transition;
}

[data-disabled="true"] {
  @apply opacity-70 pointer-events-none delay-100;
}

span.highlight {
  font-weight: 600;
  color: #a56602;
}

a.highlight {
  font-weight: 600;
  color: #a56602;
  text-decoration: none;
}

/* END Text */

.page-header {
  margin-bottom: 30px;
}

.text-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 18px;
}

.text-input label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 7px;
}

.text-input input,
.text-input textarea {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #999;
  transition: border-color 0.1s ease-in;
  transition-property: border-color, background-color;
  color: #222;
  margin-bottom: 0;
}

.text-input textarea {
  padding: 14px;
  height: 200px;
}

.text-input input:focus {
  outline: 0;
  border-color: #e2b21f;
}

.text-input.clean input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #999;
}

.text-input input.parsley-error,
.text-input textarea.parsley-error {
  border-color: #b80d22;
}

.parsley-errors-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-top: 4px;
  font-size: 12px;
}

.parsley-errors-list li {
  color: #b80d22;
}

input,
button {
  -webkit-appearance: none;
}

hr {
  border: none;
  border-bottom: 1px solid #ddd;
}

input:disabled {
  background-color: #f8f8f8;
}

.upside-down {
  transform: rotate(-180deg);
}

/* purgecss ignore */
.chart-tooltip {
  background: #333;
  padding: 10px 12px;
  border-radius: 8px;
  max-width: 100px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

/* purgecss ignore */
.chart-tooltip span {
  display: block;
  color: #fff;
  text-align: center;
}

/* purgecss ignore */
.chart-tooltip span.label {
  margin-bottom: 4px;
}

/* purgecss ignore */
.chart-tooltip span.value {
  font-weight: 600;
}

.floating-menu-trigger {
  font-style: normal;
}

img[src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="] {
  visibility: hidden;
}

@media (min-width: 768px) {
  .visible-xs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    max-width: 736px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1168px;
  }
}

.small-container {
  @apply mx-auto w-full;

  padding-bottom: 1px;
}
@media (min-width: 768px) {
  .small-container {
    max-width: 600px;
  }
}

.highlight:not(span) {
  background-color: #ffff99;
  transition: background-color 0.6s ease-out;
}

span.title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

span.text {
  display: block;
  font-size: 16px;
  margin-bottom: 6px;
}

span.text:last-child {
  margin-bottom: 0;
}

.interrogation-icon {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.overriding-page {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;

  top: 0;
  left: 0;
}

.overriding-page header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.overriding-page h1 {
  font-size: 28px;
  text-transform: uppercase;
  border-bottom: 3px solid #ffbb2d;
  font-weight: bold !important;
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}

.overriding-page h2,
.overriding-page p {
  margin: 0;
}

.overriding-page h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
}

.overriding-page p {
  font-size: 16px;
}

.overriding-page .section {
  margin-bottom: 18px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.divider {
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 22px;
  margin-bottom: 28px;
}

.divider span {
  background-color: #fff;
  padding: 0 10px;
  text-transform: uppercase;
  z-index: 10;
  color: #666;
  margin-left: 50%;
  transform: translateX(-50%);
}

.divider .divider-border {
  width: calc(100% - 10px);
  height: 2px;
  background-color: #dadada;

  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .divider {
    justify-content: center;
  }

  .divider .border {
    width: calc(100% - 40px);
    left: 20px;
  }

  .divider span {
    margin-left: 0;
    transform: none;
  }
}

.fetched-all-message {
  display: block;
  text-align: left;
  font-size: 16px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**** Components ****/

/* Modal trigger */

.modal-trigger {
  display: flex;
  align-items: center;

  font-weight: 600;
}

.modal-trigger:before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #dfaf1d;
  border-radius: 50%;
  margin-right: 10px;
}

.modal-trigger span {
  color: #dfaf1d;
}

/* Helper classes */

.rotate-animation {
  transition: transform 0.2s ease-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spin {
  animation: spin 1.5s linear infinite;
}

.bounce-fast {
  animation: bounce-fast 0.2s linear infinite;
}

/*** Animations ***/

/* Spin */

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bounce-fast {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}

.fade-loading {
  position: relative;
}

.fade-loading:after {
  @apply bg-gray-100;

  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  pointer-events: none;
  transition: opacity 0.1s linear;
  opacity: 0;
  visibility: visible;
}

.loading-nodelay:after {
  transition-delay: 0s !important;
}

.loading {
  pointer-events: none;
  border-radius: 8px;
  overflow: hidden;
  visibility: hidden;
}

.loading:after {
  @apply animate-pulse;

  opacity: 1 !important;
  transition: none;
}

ins.adsbygoogle {
  display: block;
  width: 100%;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

svg {
  color: inherit;
}

path,
rect,
circle,
line,
polygon,
polyline {
  stroke: currentColor;
  color: inherit;
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
  }
}

/** Question chat **/
@media (min-width: 768px) {
  #questionnaire.with-chat {
    #questionnaire-filters {
      display: none;
    }

    #questionnaire-wrapper {
      padding-right: 400px;
    }
  }
}
/** END of Question chat **/

.overflow-clip {
  overflow: clip;
}
